import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  Alert,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../redux/hooks/useSelector'
import { ContactAdressInput } from '../ClientInformationDialog/ContactAdressInput'
import { ClientInformationDialogFooter } from '../ClientInformationDialog/ClientInformationDialogFooter'
import { closeDialog, openDialog } from '../../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../../constants/dialogConstants'
import { ClientNameContainer } from '../ClientInformationDialog/ClientNameContainer'
import { ClientPersonalDataContainer } from '../ClientInformationDialog/ClientPersonalDataContainer'
import { Client, ClientPortfolioType, ClientRole, requiredClientAttributes } from '../../redux/reducers/clients'
import { emptyClient, emptyClientPortfolioType } from '../../constants/initialStates'
import { useClientApi } from '../../hooks/useClientApi'
import { changeFormValidity, setErrors } from '../../redux/actions/userActions'
import { ActionFailedAlert } from './ActionFailedAlert'
import { ActionAlert } from './ActionAlert'
import Autocomplete from '@mui/material/Autocomplete'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@date-io/date-fns'
import DatePicker from '@mui/lab/DatePicker'
import SwipeableViews from 'react-swipeable-views'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { ColoredIconButton } from '../common/ColoredIconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import InsightsIcon from '@mui/icons-material/Insights'
import { useRoleApi } from '../../hooks/useRoleApi'
import { TFunction, useTranslation } from 'react-i18next'
import { RootState } from '../../redux/reducers/root'
import { CLIENT_CATEGORY, CLIENT_EXPERIENCE, CLIENT_LOSS_ABILITY, CLIENT_PREFERENCE } from '../../constants/codeLists'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { codeListCodeItems } from '../../utils/codeListToArrowSelect'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useInvestmentApi } from '../../hooks/useInvestmentApi'
import { newThemeObject } from '../../newTheme'

// TODO
const useStyles = makeStyles(theme => ({
  clientInformationDialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-around',
  },
  clientInformationDialogHeaderOrange: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'orange',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-around',
  },
  separator: {
    flexGrow: 1,
  },
  clientInformationDialog: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  counterPartyIdContainer: {
    padding: theme.spacing(1),
  },
  counterpartyIdLabel: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  marginTopOverride: {
    marginTop: '0px',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  errorBorder: {
    borderColor: theme.palette.error.main,
  },
  chips: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  actionButtons: {
    width: '50%',
  },
  indicator: {
    backgroundColor: '#1149ea',
    color: '#1149ea',
  },
  activeTabColor: {
    '&.Mui-selected': {
      color: '#1149ea',
    },
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  rowOrderState: {
    borderRadius: '1rem',
    height: '2rem',
    width: 'fit-content',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  rowOrderStateText: {
    opacity: '0.5',
    fontWeight: '500',
    fontSize: '0.85rem',
    margin: 0,
  },
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const getKCLabelForClient = (client: Client, t: TFunction<"translation", undefined>) => {
  if (!client.createdKcUser) {
    return t("KC_CREATE_USER");
  } else if (client.activeKcUser) {
    return t("KC_DISABLE_USER");
  } else { // !client.activeKcUser
    return t("KC_ENABLE_USER");
  }
}

const getKCStatusForClient = (client: Client, t: TFunction<"translation", undefined>) => {
  if (!client.createdKcUser) {
    return t("HAS_NOT_KC_ACTIVE_USER");
  } else if (client.activeKcUser) {
    return t("HAS_KC_ACTIVE_USER");
  } else { // !client.activeKcUser
    return t("HAS_KC_INACTIVE_USER");
  }
}

interface Props {
  portfolio: ClientPortfolioType
  errors: Record<string, boolean>
  index: number
  onPortfolioChange: (index: number, value: string | boolean, field: string) => void
  handleSave?: (virtual?: boolean) => void
  virtual?: boolean
  handleRemove?: (index: number) => void
}

export const PortfolioDetail: React.FC<Props> = ({
  portfolio,
  errors,
  index,
  onPortfolioChange,
  handleSave,
  handleRemove,
  virtual,
}) => {
  const { t } = useTranslation()

  return (
    <Paper style={{ padding: '5px', height: '80px', margin: '5px' }}>
      <Grid container spacing={2} style={{ height: '80px' }} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={5}
          style={{ height: '60px', display: 'flex', alignContent: 'center' }}
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            fullWidth
            required
            variant="standard"
            label={t('NAME')}
            helperText={errors['name'] ? t('REQUIRE_ITEM') : ''}
            error={errors['name']}
            value={portfolio?.name}
            onChange={event => {
              onPortfolioChange(index, event.target.value, 'name')
            }}
          />
        </Grid>
        <Grid
          item
          xs={5}
          style={{ height: '60px', display: 'flex', alignContent: 'center' }}
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            fullWidth
            variant="standard"
            label={t('EXTERNAL_ID')}
            helperText={errors['externalId'] ? t('UNREQUIRE_ITEM') : ''}
            error={errors['externalId']}
            value={portfolio?.externalId}
            onChange={event => {
              onPortfolioChange(index, event.target.value, 'externalId')
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{ height: '60px', display: 'flex', alignContent: 'center' }}
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            fullWidth
            id="standard-select-currency"
            select
            label={t('CURRENCY')}
            value={portfolio?.currency}
            onChange={event => {
              onPortfolioChange(index, event.target.value, 'currency')
            }}
          >
            <MenuItem key="CZK" value="CZK">
              CZK
            </MenuItem>
            <MenuItem key="USD" value="USD">
              USD
            </MenuItem>
            <MenuItem key="EUR" value="EUR">
              EUR
            </MenuItem>
            <MenuItem key="GBP" value="GBP">
              GBP
            </MenuItem>
          </TextField>
        </Grid>
        {/*                <Grid item xs={3} style={{height: '60px', display: 'flex', alignContent: 'center'}} alignItems="center"
                      justifyContent="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={portfolio?.type === 'PT_VIRTUAL'}
                                onChange={(event) => {
                                    onPortfolioChange(index, event.target.checked ? 'PT_VIRTUAL' : 'PT_COMMON', 'type');
                                }}
                                name="checked"
                                color="primary"
                            />
                        }
                        label="Virtuální portfolio"
                        labelPlacement="top"
                    />
                </Grid>*/}
        <Grid
          item
          xs={1}
          style={{ height: '60px', display: 'flex', alignContent: 'center' }}
          alignItems="center"
          justifyContent="center"
        >
          {handleSave && <ColoredIconButton icon={<AddIcon />} size="small" onClick={() => handleSave(virtual)} />}
          {!handleSave && handleRemove && (
            <ColoredIconButton
              icon={<RemoveIcon />}
              size="small"
              disabled={portfolio.portfolioId ? true : false}
              onClick={() => handleRemove(index)}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export const ClientInformationDialog = () => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const { postClient, updateClient, getClientRoles } = useClientApi()
  const foundDialog = dialogs.find(dialog => dialog.id === 'CLIENT_INFORMATION_DIALOG_ID')
  const open = !!foundDialog
  const [contactAdressCheckState, setContactAdressState] = React.useState(false)
  const [client, setClient] = useState<Client>(emptyClient)
  const [newPortfolio, setNewPortfolio] = useState<ClientPortfolioType>(emptyClientPortfolioType)
  const clientRoles = useSelector(state => state.clients.clientRoles)
  const valid = useSelector(state => state.useractions.valid)
  const dispatch = useDispatch()
  const errors = useSelector(state => state.useractions.errors)
  const [clientSent, setClientSent] = useState(false)
  const { codeList, getCodeList } = useInstrumentApi()

  const roles: string[] = useSelector((state: RootState) => state.roles.roles)

  const { getRoles } = useRoleApi()

  const { t } = useTranslation()

  useEffect(() => {
    getClientRoles()
    getCodeList()
  }, [])

  useEffect(() => {
    if (foundDialog?.payload.client) {
      setClient(foundDialog?.payload.client)
      getRoles(foundDialog?.payload.client.clientId)
    }
  }, [foundDialog])

  useEffect(() => {
    if (client.permanentAddress === null) {
      setClient({
        ...client,
        permanentAddress: {
          addressId: null,
          street: null,
          town: null,
          zipCode: null,
          country: null,
          addressType: 'PERMANENT',
        },
      })
    }
  }, [client.permanentAddress])

  useEffect(() => {
    setClient({ ...client, roles: roles })
  }, [roles])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
      setContactAdressState(false)
      setClient(emptyClient)
      dispatch(setErrors({}))
      dispatch(changeFormValidity(true))
    }
  }, [])

  const isValid = () => {
    let valid = true
    let errorKeys = []
    const errors: {
      [key: string]: boolean
    } = {}
    for (const [key, value] of Object.entries(client)) {
      if (!requiredClientAttributes.includes(key)) continue
      if (key === 'roles' && (!value || value.length === 0)) {
        valid = false
        errors[key] = true
        errorKeys.push(key)
        continue
      }
      if (!value || (value && !String(value).trim())) {
        valid = false
        errors[key] = true
        errorKeys.push(key)
      }
    }

    for (const [key, value] of Object.entries(client.permanentAddress)) {
      if (!requiredClientAttributes.includes(key)) continue

      if (!value || (value && !String(value).trim())) {
        valid = false
        errors[key] = true
        errorKeys.push(key)
      }
    }

    if (client.shouldHaveKcUser && !client.email) {
      valid = false
      errors['email'] = true
      errorKeys.push('email')
    }

    if (errorKeys.length != 0) {
      document?.querySelector(`input[id=${errorKeys[0]}]`)?.scrollIntoView({ behavior: 'smooth' })
    }

    dispatch(setErrors(errors))
    return valid
  }

  const contactAdressCheckStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactAdressState(event.target.checked)
    setClient({
      ...client,
      contactAddress: {
        addressId: null,
        street: null,
        town: null,
        zipCode: null,
        country: null,
        addressType: 'CONTACT',
      },
    })
  }

  const handleClose = () => {
    dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
    setContactAdressState(false)
    setClient(emptyClient)
    dispatch(setErrors({}))
    dispatch(changeFormValidity(true))
  }

  const onSubmitClick = () => {
    if (isValid()) {
      handleSave()
    }
  }

  const trimAllValues = (object: Record<string, any>) => {
    const objectCopy: Record<string, any> = { ...object }
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === 'string') {
        objectCopy[key] = value.trim()
      }
      if (typeof value === 'object' && value !== null) {
        objectCopy[key] = trimAllValues(value)
      }
    }
    return objectCopy
  }

  const handleSave = () => {
    if (clientSent) return
    setClientSent(true)
    const clientCopy = client //trimAllValues(client) as Client
    if (foundDialog?.payload.type === 'new') {
      postClient(clientCopy).finally(() => {
        setClientSent(false)
      })
    } else if (foundDialog?.payload.type === 'edit') {
      updateClient(clientCopy).finally(() => {
        setClientSent(false)
      })
    }
  }

  const onClientAttributeChange = (name: string) => (value: any) => {
    setClient({ ...client, [name]: value })
    if (value.trim()) {
      dispatch(setErrors({ ...errors, [name]: undefined }))
    }
  }

  const onClientPermanentAddressChange = (name: string) => (value: any) => {
    setClient({ ...client, permanentAddress: { ...client.permanentAddress, [name]: value } })
    if (value.trim()) {
      dispatch(setErrors({ ...errors, [name]: undefined }))
    }
  }

  const onClientContactAddressChange = (name: string) => (value: any) => {
    setClient({ ...client, contactAddress: { ...client.contactAddress, [name]: value } })
  }

  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientAttributeChange(name)(event.target.value)
  }

  const onClientCheckboxChangeCreator = (name: string) => (event: any) => {
    setClient({ ...client, [name]: event.target.checked })
  }

  const handleDateChangeCreator = (name: string) => (date: Date | null) => {
    setClient({ ...client, [name]: date })
  }

  const onClientRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setClient({ ...client, roles: event.target.value as string[] })
  }

  const onPortfolioChange = (index: number, value: string | boolean, field: string) => {
    if (index === -1) {
      setNewPortfolio({ ...newPortfolio, [field]: value })
    } else {
      let clientCopy = { ...client }
      let portfolio = clientCopy.portfolios[index]
      portfolio = { ...portfolio, [field]: value }
      clientCopy.portfolios[index] = portfolio
      setClient(clientCopy)
    }
  }

  const handleRemovePortfolio = (index: number) => {
    client.portfolios.splice(index, 1)
    let clientCopy = { ...client }
    setClient(clientCopy)
  }

  const handleSavePortfolio = (virtual?: boolean) => {
    let clientCopy = { ...client }
    clientCopy.portfolios.push({ ...newPortfolio, type: virtual ? 'PT_VIRTUAL' : 'PT_COMMON' })
    setClient(clientCopy)
    setNewPortfolio(emptyClientPortfolioType)
  }
  const [tabIndex, setTabIndex] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  const { getInvestments } = useInvestmentApi()
  const investments = useSelector(state => state.investments)

  const handleDownLoad = () => () => {
    const linkSource = `data:application/pdf;base64,${investments.investments[0].pdfContent}`
    const downloadLink = document.createElement('a')
    const fileName = 'id_' + client.surname + '_' + investments.investments[0].signatureDate + '.pdf'
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  useEffect(() => {
    if (
      client &&
      (!investments ||
        investments.investments.length === 0 ||
        (investments.investments[0] && investments.investments[0].clientId !== client.clientId)) &&
        foundDialog?.payload?.type !== 'new'
    )
      getInvestments(client.clientId)
  }, [client])

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '10px' }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          classes={{
            indicator: classes.indicator,
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="portfolios"
        >
          <Tab
            label={t('CLIENT')}
            {...a11yProps(tabIndex)}
            className={classes.activeTabColor}
            iconPosition="start"
            icon={<PersonOutlineIcon />}
            style={{ textTransform: 'none', fontSize: '1.1rem', fontWeight: 700 }}
          />

          <Tab
            label={t('REAL_PORTFOLIOS')}
            {...a11yProps(tabIndex)}
            className={classes.activeTabColor}
            iconPosition="start"
            icon={<AccountBalanceWalletIcon />}
            style={{ textTransform: 'none', fontSize: '1.1rem', fontWeight: 700 }}
          />

          <Tab
            label={t('VIRTUAL_PORTFOLIO')}
            {...a11yProps(tabIndex)}
            className={classes.activeTabColor}
            iconPosition="start"
            icon={<InsightsIcon />}
            style={{ textTransform: 'none', fontSize: '1.1rem', fontWeight: 700 }}
          />
        </Tabs>
      </Box>

      <DialogContent className={classes.clientInformationDialog}>
        <TabPanel value={tabIndex} index={0}>
          <div style={{ width: '100%', height: '100%', padding: '10px' }}>
            <Grid container spacing={1}>
              <ClientNameContainer client={client} onClientAttributeChange={onClientAttributeChange} />
              <Grid container spacing={2}>
                <ClientPersonalDataContainer
                  client={client}
                  onClientAttributeChange={onClientAttributeChange}
                  onClientPermanentAddressChange={onClientPermanentAddressChange}
                  onClientDateChange={handleDateChangeCreator}
                />
                {contactAdressCheckState && (
                  <ContactAdressInput client={client} onClientContactAddressChange={onClientContactAddressChange} />
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contactAdressCheckState}
                        onChange={contactAdressCheckStateChange}
                        name="checked"
                        color="primary"
                      />
                    }
                    label={t('CONTACT_ADDRESS_NOT_SAME')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{ backgroundColor: client.activeKcUser ? newThemeObject.palette.state.filled : newThemeObject.palette.state.canceled }}
                    className={classes.rowOrderState}>
                    <p className={classes.rowOrderStateText}>{getKCStatusForClient(client, t)}</p>
                  </div>
                </Grid>
                {!client.activeKcUser && <>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client.shouldHaveKcUser}
                          onChange={onClientCheckboxChangeCreator('shouldHaveKcUser')}
                          id="shouldHaveKcUser"
                          color="primary"
                        />
                      }
                      label={getKCLabelForClient(client, t)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {!client.createdKcUser && client.shouldHaveKcUser &&
                      <Alert severity="warning">{t("KC_CREATE_CHECK_EMAIL")}</Alert>
                    }
                  </Grid>
                </>}
                {client.activeKcUser && <>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!client.shouldHaveKcUser}
                          onChange={(event) => setClient({ ...client, shouldHaveKcUser: !event.target.checked })}
                          id="shouldHaveKcUser"
                          color="primary"
                        />
                      }
                      label={getKCLabelForClient(client, t)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {!client.createdKcUser && client.shouldHaveKcUser &&
                      <Alert severity="warning">{t("KC_CREATE_CHECK_EMAIL")}</Alert>
                    }
                  </Grid>
                </>}
                {client.createdKcUser &&
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client.shouldResetPassword}
                          onChange={onClientCheckboxChangeCreator('shouldResetPassword')}
                          id="shouldResetPassword"
                          color="primary"
                        />
                      }
                      label={t("RESET_PASSWORD")}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    id="standard-basic"
                    label={t('COMMENT')}
                    onChange={onClientInputChangeCreator('note')}
                    value={client.note}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={`${classes.counterpartyIdLabel} ${errors['roles'] && classes.errorText}`}
                  >
                    {t('CREW')}
                  </Typography>
                  <Paper
                    variant="outlined"
                    className={`${classes.counterPartyIdContainer} ${errors['roles'] && classes.errorBorder}`}
                  >
                    <FormControl fullWidth error={!valid}>
                      <Autocomplete
                        multiple
                        id="roles"
                        filterSelectedOptions
                        options={clientRoles}
                        value={client.roles ? clientRoles.filter(r => client.roles.includes(r.name)) : []}
                        getOptionLabel={(option: ClientRole) => option.name}
                        getOptionDisabled={option => client.roles && !!client.roles.find(r => r === option.name)}
                        onChange={(event: any, value: ClientRole[]) => {
                          setClient({ ...client, roles: value.map(v => v.name) })
                        }}
                        renderTags={(value: ClientRole[], getTagProps) =>
                          value.map((option: ClientRole, index: number) => (
                            <Chip classes={{ root: classes.chips }} label={option.name} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Role"
                            helperText={errors['roles'] ? t('REQUIRE_ITEM') : ''}
                            error={errors['roles']}
                          />
                        )}
                      />
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.counterpartyIdLabel}>
                    {t('COUNTERPARTIES_ID')}
                  </Typography>
                  <Paper variant="outlined" className={classes.counterPartyIdContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="standard"
                          fullWidth
                          id="standard-basic"
                          label={t('JT')}
                          onChange={onClientInputChangeCreator('clientCounterpartyId')}
                          value={client.clientCounterpartyId}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.counterpartyIdLabel}>
                    {t('INVESTMENT_QUESTIONNAIRE')} &nbsp;&nbsp;&nbsp;&nbsp;
                    {investments && investments.investments[0] && investments.investments[0] && (
                      <Button
                        onClick={handleDownLoad()}
                        style={{ cursor: 'pointer' }}
                        title={t('DOWNLOAD_QUESTIONNAIRE')}
                        startIcon={<DownloadForOfflineIcon />}
                        variant="outlined"
                      >
                        {t('DOWNLOAD_QUESTIONNAIRE')}
                      </Button>
                    )}
                  </Typography>

                  <Paper variant="outlined" className={classes.counterPartyIdContainer}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          variant="standard"
                          id="standard-basic"
                          label={t('INVESTMENT_HORIZONTE')}
                          onChange={onClientInputChangeCreator('investmentHorizonte')}
                          value={client.investmentHorizonte}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          variant="standard"
                          id="standard-basic"
                          label={t('RISK_PROFILE')}
                          onChange={onClientInputChangeCreator('riskProfile')}
                          value={client.riskProfile}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={props => (
                              <TextField variant="standard" fullWidth={true} {...props} label={t('DATE')} />
                            )}
                            inputFormat="dd/MM/yyyy"
                            value={client.investmentQuestionnaireValidTo}
                            onChange={handleDateChangeCreator('investmentQuestionnaireValidTo')}
                            className={classes.marginTopOverride}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl fullWidth error={!valid}>
                          <Autocomplete
                            id="category"
                            filterSelectedOptions
                            options={codeListCodeItems(CLIENT_CATEGORY, codeList)}
                            value={client.category || ''}
                            getOptionLabel={(a: string) => t(a)}
                            onChange={(event: any, value: string | null) => {
                              setClient({ ...client, category: value })
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="standard" placeholder={t('CLIENT_CATEGORY')} />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth error={!valid}>
                          <Autocomplete
                            id="skillAndExperience"
                            filterSelectedOptions
                            options={codeListCodeItems(CLIENT_EXPERIENCE, codeList)}
                            value={client.skillAndExperience || ''}
                            getOptionLabel={(a: string) => t(a)}
                            onChange={(event: any, value: string | null) => {
                              setClient({ ...client, skillAndExperience: value })
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="standard" placeholder={t('KONWLEDGE_AND_EXPERIENCE')} />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth error={!valid}>
                          <Autocomplete
                            id="preference"
                            filterSelectedOptions
                            options={codeListCodeItems(CLIENT_PREFERENCE, codeList)}
                            value={client.preference || ''}
                            getOptionLabel={(a: string) => t(a)}
                            onChange={(event: any, value: string | null) => {
                              setClient({ ...client, preference: value })
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="standard" placeholder={t('INVESTMENT_PREFERENCES')} />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth error={!valid}>
                          <Autocomplete
                            id="lossAbility"
                            filterSelectedOptions
                            options={codeListCodeItems(CLIENT_LOSS_ABILITY, codeList)}
                            value={client.lossAbility || ''}
                            getOptionLabel={(a: string) => t(a)}
                            onChange={(event: any, value: string | null) => {
                              setClient({ ...client, lossAbility: value })
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="standard" placeholder={t('RISK_APPROVE')} />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.counterpartyIdLabel}>
                    {t('FIX_ID')}
                  </Typography>

                  <Paper variant="outlined" className={classes.counterPartyIdContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="standard"
                          fullWidth
                          id="clientExternalId"
                          label={t('FIX_CLIENT_ID')}
                          onChange={onClientInputChangeCreator('clientExternalId')}
                          value={client.clientExternalId}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <PortfolioDetail
              portfolio={newPortfolio}
              errors={errors}
              onPortfolioChange={onPortfolioChange}
              handleSave={handleSavePortfolio}
              virtual={false}
              index={-1}
            />
            <div
              style={{
                width: '100%',
                backgroundColor: '#2e99ff',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
              }}
            >
              {client.portfolios.map((portfolio: ClientPortfolioType, index: number) => {
                return portfolio.type !== 'PT_VIRTUAL' ? (
                  <PortfolioDetail
                    portfolio={portfolio}
                    errors={errors}
                    onPortfolioChange={onPortfolioChange}
                    index={index}
                    handleRemove={(indexS: number) => {
                      handleRemovePortfolio(indexS)
                    }}
                  />
                ) : (
                  <></>
                )
              })}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <PortfolioDetail
              portfolio={newPortfolio}
              errors={errors}
              onPortfolioChange={onPortfolioChange}
              virtual={true}
              handleSave={handleSavePortfolio}
              index={-1}
            />
            <div
              style={{
                width: '100%',
                backgroundColor: '#ffbfa7',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
              }}
            >
              {client.portfolios.map((portfolio: ClientPortfolioType, index: number) => {
                return portfolio.type === 'PT_VIRTUAL' ? (
                  <PortfolioDetail
                    portfolio={portfolio}
                    errors={errors}
                    virtual={true}
                    onPortfolioChange={onPortfolioChange}
                    index={index}
                    handleRemove={(indexS: number) => {
                      handleRemovePortfolio(indexS)
                    }}
                  />
                ) : (
                  <></>
                )
              })}
            </div>
          </div>
        </TabPanel>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ClientInformationDialogFooter
          displaySection={tabIndex === 0}
          client={client}
          handleClose={handleClose}
          handleSave={onSubmitClick}
        />
      </DialogActions>
      <ActionFailedAlert handleClose={() => { }} />
      <ActionAlert />
    </Dialog >
  )
}
